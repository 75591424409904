import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import API_URL from "../../Helper/base";
import Loader from "../Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import Mask from "../../assets/Mask2.png";
import "./SearchResults.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/Navigation/Nav";

function SearchResults({toggleTheme}) {
  let urlParams = useParams().name;
  urlParams = urlParams.replace(":", "");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageCount = 30;
  const page = Math.ceil(results.length / pageCount) + 1;
  useEffect(() => {
    getResults();
    setLoading(true);
  }, [urlParams]);

  async function getResults() {
    let res = await axios.get(
      `${API_URL}api/search/keyword=${urlParams}&page=${page}`
    );
    setLoading(false);
    let data = res.data;
    const merge = [...results, ...data];
    setResults(merge);
    document.title = urlParams + " - ASIANdrama";
  }
  const fetchMoreData = () => {
    getResults();
  };
  return (
    <>
      <Nav toggleTheme={toggleTheme} />
      <div className="serarch-result-heading">
        <h1>
          Search <span>Results :</span>
        </h1>
      </div>
      {loading && <Loader />}
      {!loading && (
        <>
          <InfiniteScroll
            dataLength={results.length}
            next={fetchMoreData}
            hasMore={true}
            loader={
              <TailSpin
                height="50"
                width="50"
                color="black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem",
                }}
                wrapperClass=""
                visible={true}

              />
            }

            scrollableTarget="scrollableDiv"
            scrollThreshold="200px"
            pullDownToRefreshThreshold={50}
          >
            <div className="search-drama asiandrama">
              <div className="search-container drama">
                {results.map((item, i) => (
                  <div className="card" key={item.id}>
                    <Link to={"/drama-watch/" + item.id}>
                      <LazyLoadImage
                        src={item.image}
                        alt={item.title}
                        height={"100%"}
                        effect="blur"
                        width={"100%"}
                        delayMethod={"debounce"}
                        placeholderSrc={Mask}
                      />
                      <p>{item.title}</p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </InfiniteScroll>
          {results.length === 0 && <h2 className="search-notfound" >No Search Results Found</h2>}
          <Footer />
        </>
      )}
    </>
  );
}

export default SearchResults;
