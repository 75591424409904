import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";
// import AnimeDetails from "./pages/AnimeDetails/AnimeDetails";
// import FavouriteAnime from "./pages/FavouriteAnime";
// import Home from "./pages/Home";
// import PopularAnime from "./pages/PopularAnime";
// import SearchResults from "./pages/SearchResults";
// import Top100Anime from "./pages/Top100Anime";
// import TrendingAnime from "./pages/TrendingAnime";
// import WatchAnime from "./pages/WatchAnime/WatchAnime";
import Home from "./Pages/Home/Home";
import Movies from "./Pages/Movie/Movies";
import Popular from "./Pages/Popular/Popular";

import SearchResults from "./Pages/SearchResults/SearchResults";

import { trackWindowScroll } from "react-lazy-load-image-component";
import { Suspense, lazy } from "react";
// import Search from "./components/Navigation/Search";
import { IoMdArrowRoundUp } from "react-icons/io";
import "./App.scss";

import Watching from "./Pages/Watching/Watching";
import Raw from "./Pages/Raw/Raw";
import Ongoing from "./Pages/Ongoing/Ongoing";
import Error from "./Pages/NotFound/Error";
import TermsCondition from "./Pages/Terms/TermsCondition";
import PrivacyPolicy from "./Pages/Terms/PrivacyPolicy";
import IntroPage from "./Pages/Intro/IntroPage";

function App() {
  const [visible, setVisible] = useState(false);
  // const Home = lazy(() => import("./Pages/Home/Home"));

  // const SearchResults = lazy(() => import("./Pages/SearchResults/SearchResults"));
  // const Movies = lazy(() => import("./pages/Movies/Movies"));
  // const Popular = lazy(() => import("./pages/Popular/Popular"));

  // const Watching = lazy(() => import("./pages/Watching/Watching"));
  // const AnimeDetails = lazy(() => import("./pages/AnimeDetails/AnimeDetails"));
  // const Error = lazy(() => import("./pages/NotFound/Error"));
  const [theme, setTheme] = useState(false);

  const toggleTheme = () => {
    setTheme(!theme);
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    toTop();
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <div className={theme ? "asiandrama  dark " : " asiandrama "}>
        <Router>
          {/* className={theme ? "asiandrama  dark " : " asiandrama " } */}

          <Suspense>
            <Routes>
              <Route
                path="/"
                element={<IntroPage toggleTheme={toggleTheme} />}
              />
              <Route
                path="/home"
                element={<Home toggleTheme={toggleTheme} />}
              />

              <Route
                path="/movies"
                element={<Movies toggleTheme={toggleTheme} />}
              />
              <Route
                path="/popular"
                element={<Popular toggleTheme={toggleTheme} />}
              />
              <Route
                path="/drama-watch/:id"
                element={<Watching toggleTheme={toggleTheme} />}
              />
              <Route
                path="/search/keyword=:name"
                element={<SearchResults toggleTheme={toggleTheme} />}
              />

              <Route
                path="/ongoing"
                element={<Ongoing toggleTheme={toggleTheme} />}
              />
              <Route path="/raw" element={<Raw toggleTheme={toggleTheme} />} />
              <Route
                path="/terms-condition"
                element={<TermsCondition toggleTheme={toggleTheme} />}
              />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy toggleTheme={toggleTheme} />}
              />

              <Route path="*" element={<Error />} />
            </Routes>
          </Suspense>
        </Router>
      </div>

      {visible && (
        <button
          id="top"
          className="top"
          onClick={toTop}
          style={{ display: visible ? "grid" : "none" }}
        >
          <IoMdArrowRoundUp />
        </button>
      )}
    </>
  );
}

export default trackWindowScroll(App);
