import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Search from "../Search/Search";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { HiMenuAlt3 } from "react-icons/hi";
import { CSSTransition } from "react-transition-group";
import useScrollBlock from "../useScrollBlock";
import "./Nav.scss";
import Logo from "../../assets/Logo2.png"

const Nav = ({ toggleTheme }) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);

  const allowscrollMenu = () => {
    allowScroll();
    setOpen(!open);
  };
  const blockScolling = () => {
    blockScroll();

    setOpen(!open);
  };
  const closeSearch = () => {
    setSearch(!search);
  };

  return (
    <>
      <nav>
        <div className="navbar">
          <div className="search-wrapper">
            <div className="logo heading">
              <NavLink to={"/"}>
               <img src={Logo} alt="LOGO" />
              </NavLink>
            </div>

            <div className="search">
              <Search />
            </div>
            {/* <Toggle toggleTheme={toggleTheme} /> */}
          </div>
          <div className="menu-btn">
            {/* <Toggle toggleTheme={toggleTheme} /> */}
            <div className="search-btn" onClick={closeSearch}>
              <FiSearch />
            </div>
            <button className="hamburger-btn" onClick={blockScolling}>
              <HiMenuAlt3 />
            </button>
          </div>
        </div>
        <ul className="nav-links">
          <li>
            <NavLink to="/home">
              <h3>recently added sub</h3>
            </NavLink>
          </li>
          <li>
            <NavLink to="/raw">
              <h3>raw</h3>
            </NavLink>
          </li>
          <li>
            <NavLink to="/movies">
              <h3>movies</h3>
            </NavLink>
          </li>
          {/* <li >
            <NavLink
              to={"/kshow"}
           
            >
              <h3>show</h3>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/popular">
              <h3> popular</h3>
            </NavLink>
          </li>
          <li>
            <NavLink to="/ongoing">
              <h3>ongoing</h3>
            </NavLink>
          </li>
          <li>
            <a
              href="https://grainsslaughter.com/ht8nmtwf?key=97b747fd808c692777cb4b0ae609cdb7"
              target="_blank"
              rel="noopener noreferrer"
              // onClick={allowscrollMenu}
            >
              <h3>Ads</h3>
            </a>
          </li>
        </ul>
        <CSSTransition
          in={search}
          timeout={500}
          classNames="my-node"
          unmountOnExit
        >
          <div className="search-box">{search && <Search />}</div>
        </CSSTransition>
        <CSSTransition
          in={open}
          timeout={500}
          classNames="my-burger"
          unmountOnExit
        >
          <div className="hamburger-menu">
            <NavLink to="/home" onClick={allowscrollMenu}>
              <h3>recently added sub</h3>
            </NavLink>
            <NavLink to="/raw" onClick={allowscrollMenu}>
              <h3>raw</h3>
            </NavLink>
            <NavLink to="/movies" onClick={allowscrollMenu}>
              <h3>movies</h3>
            </NavLink>
            <NavLink to="/popular" onClick={allowscrollMenu}>
              <h3> popular</h3>
            </NavLink>
            <NavLink to="/ongoing" onClick={allowscrollMenu}>
              <h3>ongoing</h3>
            </NavLink>
            <a
              href="https://grainsslaughter.com/ht8nmtwf?key=97b747fd808c692777cb4b0ae609cdb7"
              target="_blank"
              rel="noopener noreferrer"
              onClick={allowscrollMenu}
            >
              <h3>Ads</h3>
            </a>

            <div className="btn">
              <button className="close">
                <IoMdClose onClick={allowscrollMenu} />
              </button>
            </div>
          </div>
        </CSSTransition>
        {open && <div className="shadow"></div>}
      </nav>
    </>
  );
};

export default Nav;
