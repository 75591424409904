import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import "./Episodes.scss"
import API_URL from "../../../Helper/base";
const Episodes = () => {
  let id = useParams().id;
  const [watchingEpisode, setWatchingEpisode] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    WatchingDrama();
  }, [id]);

  async function WatchingDrama() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    let res = await axios.get(`${API_URL}api/watching/${id}`);
    setLoading(false);

    setWatchingEpisode(res.data.episodes);
    
    
  }

  return (
    <>
      
      {!loading && (
        <div className="episodes">
          <div className="heading">
            <h1>List Episodes</h1>
          </div>
          <div className="episodes-container">
            {watchingEpisode.map((item, i) => (
              <>
                <NavLink to={"/drama-watch/" + item.id} >
              <div className="episode-card" key={item.id}>
                 <div className="episode">

                    <p>{item.title}</p>

                  <span>{item.time}</span>
                 </div>
              </div>
                </NavLink>
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Episodes;
