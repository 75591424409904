import React from "react";
import "./error.scss";
import Footer from "../../components/Footer/Footer"
import Nav from "../../components/Navigation/Nav";
const error = ({toggleTheme}) => {
  return (
    <>
    <Nav toggleTheme={toggleTheme} />
      <div className="error-page">
      <div class="site">
	<div className="sketch">
		<div className="bee-sketch red"></div>
		<div className="bee-sketch blue"></div>
	</div>

<h1 className="error-title">404:
	<small>Page Not Found</small></h1>
</div>
</div>
      <Footer/>
    </>
  );
};

export default error;
