import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API_URL from "../../Helper/base";
import "./Raw.scss";
import { RiPlayMiniFill } from "react-icons/ri";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import Loader from "../Loader/Loader";
import Mask from "../../assets/Mask2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/Navigation/Nav";
const Raw = ({toggleTheme}) => {
  const [raw, setRaw] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageCount = 30;
  const pageNo = Math.ceil(raw.length / pageCount) + 1;
  useEffect(() => {
    AsianDrama();
  }, []);

  async function AsianDrama() {
    let res = await axios.get(`${API_URL}api/raw/page=${pageNo}`);
    setLoading(false);
    let data = res.data;
    const merge = [...raw, ...data];
    setRaw(merge);

    document.title = "Popular Drama - ASIANdrama";
  }
  const fetchMoreData = () => {
    AsianDrama();
  };
  return (
    <>
      <Nav toggleTheme={toggleTheme} />
      {loading && <Loader />}
      {!loading && (
        <>
          <InfiniteScroll
            dataLength={raw.length}
            next={fetchMoreData}
            hasMore={true}
            loader={
              <TailSpin
                height="50"
                width="50"
                color="black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem",
                }}
                wrapperClass=""
                visible={true}
              />
            }
            scrollableTarget="scrollableDiv"
            scrollThreshold="200px"
          >
            <div className="raw-drama asiandrama">
              <div className="heading">
                <h1>
                  Raw <span>Drama</span>
                </h1>
              </div>
              <div className="raw-container drama">
                {raw.map((item, i) => (
                  <div className="card" key={item.id}>
                    <Link to={"/drama-watch/" + item.id}>
                      <div className="image-container">
                        <LazyLoadImage
                          src={item.image}
                          alt={item.title}
                          height={"100%"}
                          effect="blur"
                          width={"100%"}
                          delayMethod={"debounce"}
                          placeholderSrc={Mask}
                        />
                        <div className="play-icon">
                          <RiPlayMiniFill />
                        </div>
                      </div>
                      <p>
                        {item.title}-{item.episode}
                      </p>

                      <span>{item.time}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </InfiniteScroll>
          <Footer />
        </>
      )}
    </>
  );
};

export default Raw;
